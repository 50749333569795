import React from "react"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import DropDownBox from "../Components/DropDownBox"

function Header() {
  const [showModal, setShowModal] = useState(false)
  const [isModalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 300
      if (window.scrollY > scrollThreshold && !showModal) {
        setShowModal(true)
        setModalVisible(true)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [showModal])

  const closeModal = () => {
    setModalVisible(false)
  }

  return (
    <header className='header__container'>
      <h2>Web Developer | Designing Modern and Responsive Websites</h2>
      <Link
        to='/portfolio'
        className='header-link'
        title='See All the Projects'
      >
        portfolio
      </Link>

      {showModal && (
        <DropDownBox
          message="Don't miss out! My Portfolio section is full of interesting projects,
           including a brand-new Quiz app.Perfect for Recruiters or Developers to explore. Click below to check it out!
          "
          isOpen={isModalVisible}
          onClose={closeModal}
        />
      )}
    </header>
  )
}

export default Header
