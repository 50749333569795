import React from "react"
import { MdClose } from "react-icons/md"
import { Link } from "react-router-dom"

function DropDownBox({ message, isOpen, onClose }) {
  if (!isOpen) return null

  return (
    <div className='dropdown-backdrop'>
      <div className='dropdown-box'>
        <div className='dropdown-close'>
          <button onClick={onClose}>
            <MdClose />
          </button>
        </div>

        <p>{message}</p>
        <Link to='/portfolio'>Portfolio</Link>
      </div>
    </div>
  )
}

export default DropDownBox
